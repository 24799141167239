.ssd{
    color:red
} 
/* ::ng-deep .cke_contents  {
    height: 260px !important;
  } */
  .logo{
    justify-content: space-between;
    align-items: center;
    height: 80px;
    /* background-color: #fff; */
  }

  .image {
    margin-left: 35px;
    height: 50px;
  }
  
  .cardhighlight{
    border-radius: 10px !important;
    box-shadow: 0px 3px 3px #c0c0c0 !important;
  }
  
  
  .btn-add {
      padding: 1px 10px;
      font-size: 12px;
      color: #fff;
      min-width: 117px;
      height: 43px;
      background: #1c4079 0% 0% no-repeat padding-box;
      border: 0.20000000298023224px solid #707070;
      border-radius: 5px;
      opacity: 1;
      text-transform: uppercase;
    }

   i {
    float: left;
    margin-top: 4px;
  }

    .btn-add:hover {
      background-color: #1c4079;
      transition: 0.5s;
      color: #fff;
    }


    .btn-cancel {
      padding: 1px 10px;
      font-size: 12px;
      min-width: 117px;
      height: 43px;
      border: 2px solid #1c4079;
      border-radius: 5px;
      opacity: 1;
      text-transform: uppercase;
      transition: 0.5s;
     /* &:hover{
      background-color: #1c4079;
      color: #fff;
     } */
    } 
    .btn-cancel :hover{
      background-color: #1c4079;
      color: #fff;
     }

    #cke_1_contents{
      height: 250px !important;
    }

    