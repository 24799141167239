.dialogue{
    width: 350px;
    height: 366px;
    background: rgb(41, 45, 64);
    padding: 18px  10px 20px 10px;
    position: relative
}
/* .react-responsive-modal-modal{
    margin-bottom: 150px !important
} */

.buttonStyle{
    background: #1891BD;
    border-radius: 5px;
    color: white;
    height: 40;
    padding: 0px 15px;
    opacity: 1;
    font-size: 14;
    text-transform: uppercase;
    /* &:hover: {
      border: "1px solid #1891BD",
    } */
  }
.buttonStyle :hover{
    border: 1px solid #1891BD,
}

.filterdatepicker .react-calendar{
    position: fixed;
  }

.filterdatepicker{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center
}

.filterdatepicker>*:not(:first-child){
    padding-top: 10px;
    width: 97%
}

.label{
    font: normal normal normal 14px/21px Poppins;
    color: "#FFFFFF";
    opacity: 1
}


.datepickers {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start
}
.datepickers:last-child{
    padding-left: 10px
}

.MuiInputBase-root{
    padding-top:0px !important
}

#demo-simple-select{
    font-size: 14px;
}

