.Mui-expanded[aria-expanded='true']{
  border-bottom: 1px solid lightgray !important;
}

.htmlcontents{
  padding: 1rem;
}

ul.ulist,ol.olist{
  display: flex;
  flex-direction: column;
}

li.lilists{
      color:black !important;
      padding:5px !important;
      line-height: 10px !important;
  }

  ul li.lilists::before {
    content: '\2022 \00a0 \00a0 \00a0' !important;
 }
  
 ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }
  ol li {
    counter-increment: my-awesome-counter;
  }


  ol li.lilists::before {
    content: counter(my-awesome-counter) ". ";
    font-weight: bold;
  }
  
  .v-history{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2% 15%;
  }

  .image {
    height: 50px;
  }

  .info-box{
    background-color: #ffffff;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    border-radius: 10px;
    padding: 2% 5%;
    box-shadow: 3px 3px 6px #0000000F;
    width: 100%;
  }

  .info-title{
    font-size: 20px;
    font-weight: 600;
  }
  .info-detail{
    padding-top: 0.5rem;
    text-align: center;
    line-height: 20px;
  }

  .cp-card {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
}

.release{
    font-style: italic;
    font-size: 13px;
    color: lightgrey;
    position: absolute;
    top: 35%;
    right: 7%;
    z-index: 1
  }
        .heading{
          font-weight: 600;
          font-size: 18px;
        }
        /* p{
          color: black !important;
          font-weight: 500 !important;
        } */

  /* @media(max-width:768px){
    .v-history{
      padding: 2% 3% !important;
    }
    .release{
      right: 12% !important;
    }
  } */
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
/*   
  :host {
      width: 100%;
    }
  
    .innerBox {
      padding: 1% 160px 0px;
      .title-center{
        display: flex;
        align-items: center;
      }
      .card {
        padding: 12px 60px 47px 52px;
        box-shadow: 0px 0px 12px -1px #888888;
        p {
          margin: 16px;
          color: black !important;
        }
        .information {
          margin-left: 100px;
          ol {
            list-style: decimal !important;
            line-height: 1.5rem;
          }
        }
      }
    }
    /* @media (max-width:768px){
      .innerBox{
        padding: 0px 15px !important;
        .card{
          padding: 15px !important;
          .information{
            margin-left: 35px !important;
          }
        }
      }
    } */