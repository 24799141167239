.placeholder   {
  padding-left: 30px !important;
  font: normal normal normal 12px/21px Poppins;
  color: #fff !important;
  opacity: 0.6;
}
input:focus ~ .placeholder, input:valid ~ .placeholder, .placeholder.hold {
  position: absolute;
  top: 0.2em;
  left: 1.5em;
  font-size: 11px;
}

.createPassword > div > .placeholder {
  padding-top: 8px !important;
  padding-left: 0px !important;
  font: normal normal normal 14px/21px Poppins;
  color: #fff !important;
  opacity: 0.6;
}

.flexcolumn{
display: 'flex'; 
flex-direction: 'column';
justify-content: 'flex-start';
align-items: 'flex-start'
}
.flexcolumn >*:not(:last-child){
margin-bottom: 14px;
}

/* .innerInput > div > .placeholder {
margin-top: 3px !important;
padding-left: 0px !important;
font: normal normal normal 10px/16px Poppins !important;
color: #f3f3f3 !important;
opacity: 0.6;
} */

.innerInput > div > .placeholder {
margin-top: 3px !important;
margin-left: -34px !important;
font: normal normal normal 12px/16px Poppins !important;
color: #f3f3f3 !important;
opacity: 0.6;
}

.disabledInnerInput > div > .placeholder {
margin-top: -4px !important;
padding-left: 0px !important;
font: normal normal normal 10px/16px Poppins !important;
color: #6f6e73 !important;
opacity: 0.6;
}

.divideInnerInputGroup > div > .placeholder {
padding-top: 5px !important;
padding-left: 35px !important;
font: normal normal normal 12px/16px Poppins !important;
color: #f3f3f3 !important;
opacity: 0.6;
}

.loginInput > div > .placeholder {
padding-top: 8px !important;
padding-left: 28px !important;
font: normal normal normal 14px/21px Poppins;
color: #fff !important;
opacity: 0.6;
}

.changePasswordInput > div > .placeholder {
padding-top: 8px !important;
padding-left: 10px !important;
font: normal normal normal 14px/21px Poppins;
color: #fff !important;
opacity: 0.6;
}

.tab {
width:100%;
padding:25px;
font-family:sans-serif;
color:#444;
}

ul.inline{
list-style:none;
padding: 0;
padding-right: 30px;
padding-left: 30px !important;
margin-bottom:0;
-webkit-margin-before: 0;
-webkit-margin-after: 0;
-webkit-margin-start: 0px;
-webkit-margin-end: 0px;
-webkit-padding-start: 0px;   
border-bottom: 2px solid #3B3E50;
}
li {
display:inline-block;
margin-left:0;
padding:10px;
padding-right: 20px;
padding-left: 20px;
transition: all .5s;
font-weight:300;
cursor:pointer;
text-align: left;
font: normal normal normal 14px/21px Poppins;
letter-spacing: 0.3px;
color: #FFFFFF;
opacity: 1;    
border-bottom: 2px solid transparent;
}
li.selected {
border-bottom: 2px solid #1891BD !important;
color:#1891BD;
}
li.leftSelected {
color:#1891BD;
}
.react-file-reader {
display: inline-flex !important;
}
.headerNameHover:hover{
background: #343744 0% 0% no-repeat padding-box;
opacity: 1 !important;
border-radius: 15px;  
}
.noPadding {
padding: 0px !important;
}
.noMargin {
padding: 0px !important;
}
.menu-item {
padding: 0 40px;
margin: 5px 10px;
user-select: none;
cursor: pointer;
border: none;
outline: none;
}
.menu-item-wrapper.active {
border: none;
outline: none;
}
.menu-item.active {
border: none;
}
.menu-item-wrapper:focus {
outline: none !important;
}
.scroll-menu-arrow {
padding: 0px;
cursor: pointer;
}

.videoPlayerHover:hover{
opacity: 0.5 !important;
}

.videoPlayerHover1{
  opacity: 0.1 !important;
}

.swiper-slide:hover  .videoPlayerwaitingHoverText {
      color: white !important;
  }

  .swiper-slide:hover  .videoPlayerwaitingHover {
    color: white !important;
    border-color: white !important;
}

.imagePlayerHover:hover{
opacity: 0.5 !important;
}

.rc-menu__item {
background: #3B3E50 0% 0% no-repeat padding-box !important;
padding: 0;
}
.headerMenuHover:hover{
color: #1891BD !important;
}

.hoverServices:hover{
border: 1px solid #1891BD !important;
}
.hoverServices:hover span{
color: #1891BD !important;
}
.favarotiesBox:hover{
box-shadow: 3px 3px 6px #00000029 !important;
}
::-webkit-input-placeholder {
color: #FFFFFF;
}
.MuiSelect-icon{
color: #FFFFFF !important;
opacity: 0.7 !important;
top: 0px !important;
}
.MuiMenu-list{
background-color: #51535d !important;
}
.MuiMenuItem-root{
background-color: #262834 !important;
color: #FFFFFF !important;
opacity: 0.7; 
background-color: #51535d;
border: none;
padding: 10px 26px 10px 12px;
font: italic normal 300 14px/18px Poppins;
font-size: 14px !important;
}
.MuiMenuItem-root:hover{
background-color: #7a7b81 !important;
}
.MuiMenuItem-root{
background-color: transparent !important;
}
.heightScrollSearchPlater{
height: 400px;
overflow: none;
overflow-y: scroll;
padding-right: 10px;
}
::-webkit-scrollbar {
width: 5px;
height: 5px;
scrollbar-color: #3B3E50 #262834;
}

/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 1px #262834; 
border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #3B3E50 0% 0% no-repeat padding-box; 
border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #3B3E50 0% 0% no-repeat padding-box; 
}

:root{
  scrollbar-color: #3B3E50 #262834 !important;
  scrollbar-width: 1px !important;
}


.searchPlayerResponseClass {
padding-left: 40px;
padding-right: 25px;
}
.searchPlayerResponseClass1 {
padding-left: 30px;
padding-right: 30px;
}
.searchAgentResponseClass1 {
padding-left: 30px;
padding-right: 30px;
}
.hoverPlayerSearchCard:hover{
border: 1px solid #1891BD !important;
box-shadow: 0px 6px 6px #00000066 !important;
}
@media only screen and (max-width: 400px) {
.searchPlayerResponseClass {
  padding-left: 15px;
  padding-right: 15px;
}  
.searchAgentResponseClass1 {
  padding-left: 15px;
  padding-right: 15px;
}
ul.inline{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
}
@media only screen and (max-width: 990px) {
.searchPlayerResponseClass1 {
  padding-left: 0px;
  padding-right: 0px;
}
.searchAgentResponseClass1 {
  padding-left: 0px;
  padding-right: 0px;
}
}

.rowView {
padding-right: 80px;
padding-left: 80px;
}
.innerRowView {
  padding-right: 40px;
  padding-left: 40px;
}
.signUpView {
padding-right: 120px;
padding-left: 120px;
}

@media only screen and (max-width: 990px) {
  .rowView {
    padding-left: 20px;
    padding-right: 20px;
  }
  .innerRowView{
    padding-left: 20px;
    padding-right: 20px;
  }
  .signUpView {
    padding-left: 30px;
    padding-right: 30px;
  }  
}
.react-date-picker{
width: 100%;
height: 44px;
background: rgba(75, 76, 92, 0.6);
border-radius: 5px;
}
.react-date-picker__wrapper{
border: none;
}
.react-date-picker__inputGroup {
padding-left: 10px;
color: white;
border: none;
}
.react-date-picker__inputGroup__input{
color: white;
font: normal normal 300 14px/21px Poppins;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 0.64;
padding-right: 5px;
padding-left: 5px;
}
.react-date-picker__inputGroup__year {
width: 50px !important;
}
.react-date-picker__inputGroup__input:focus{
border: none !important;
outline: none !important;
}
.react-date-picker__inputGroup__input:invalid
{
background: transparent !important;
}
.react-date-picker__calendar-button{
background: rgba(75, 76, 92, 0.6);
/* opacity: 0.2; */
}
.react-date-picker__calendar-button:focus{
border: none !important;
outline: none !important;
}
.react-calendar__navigation button[disabled] {
background-color: #51535d !important;
}
.react-calendar__tile:disabled {
background-color: #51535d !important;
}

.Toastify__toast--success {
background: linear-gradient(90deg, rgb(59, 62, 80) 0%, #1891bd 100%) 0% 0% no-repeat padding-box padding-box transparent!important;
}
.Toastify__toast--error {
background: linear-gradient(90deg, rgb(59, 62, 80) 0%, rgb(255, 45, 45) 100%) 0% 0% no-repeat padding-box padding-box transparent !important;
}
.Toastify__toast {
border-radius: 5px !important;
min-height: 55px !important;
}
.Toastify__toast-body {
font: italic normal 300 16px/21px Poppins !important;
}

.react-calendar {
background: #51535d !important;
border: 1px solid #51535d !important;
font: italic normal 300 14px/18px Poppins !important;
color: white !important;
}
.react-calendar__tile--now {
background: #8ba3bb !important;
}

.react-calendar button {
color: white;
}

.react-calendar__tile--active {
background: #ffffff !important;
color: #000 !important;
}

.react-date-picker__inputGroup {
letter-spacing: 2px !important;
}

.react-calendar button:hover {
color: #000000 !important;
}

.react-profile-calender {
background: rgb(81 83 93) !important;
border: 1px solid rgb(81 83 93) !important;
font: italic normal 300 14px/18px Poppins !important;
color: white !important;
height: 50px !important;
}

.react-profile-calender .react-date-picker__inputGroup{
  padding-top: 8px !important;
}

.tableFixHead {
overflow-y: auto;
}
.tableFixHead thead th {
position: sticky;
top: 0;
}
table {
border-collapse: collapse;
width: 100%;
white-space: nowrap !important;
}
th,
td {
padding: 8px 16px;
}
th {
background: #262834;
}

/* Default state */
.rc-checkbox {
white-space: nowrap;
cursor: pointer;
outline: none;
display: inline-block;
position: relative;
line-height: 1;
vertical-align: middle;
border-radius: 50px;
}
.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
border-color: #3dbcf6;
}
.rc-checkbox-inner {
position: relative;
top: 0;
left: 0;
display: inline-block;
width: 14px;
height: 14px;
border-width: 1px;
border-style: solid;
border-radius: 3px;
border-color: #d9d9d9;
background-color: transparent;
transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.rc-checkbox-inner:after {
-webkit-transform: rotate(45deg);
transform: rotate(45deg);
position: absolute;
left: 4px;
top: 1px;
display: table;
width: 5px;
height: 8px;
border: 2px solid transparent;
border-top: 0;
border-left: 0;
content: ' ';
animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
animation-duration: 0.3s;
animation-name: amCheckboxOut;
}
.rc-checkbox-input {
position: absolute;
left: 0;
z-index: 9999;
cursor: pointer;
opacity: 0;
top: 0;
bottom: 0;
right: 0;
}
/* Checked state */
.rc-checkbox-checked:hover .rc-checkbox-inner {
border-color: #3dbcf6;
}
.rc-checkbox-checked .rc-checkbox-inner {
border-color: #3dbcf6;
background-color: #3dbcf6;
}
.rc-checkbox-checked .rc-checkbox-inner:after {
transform: rotate(45deg);
position: absolute;
left: 4px;
top: 1px;
display: table;
width: 5px;
height: 8px;
border: 2px solid #ffffff;
border-top: 0;
border-left: 0;
content: ' ';
animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
animation-duration: 0.3s;
animation-name: amCheckboxOut;
}
@media print {
.rc-checkbox-checked .rc-checkbox-inner {
  box-shadow: inset 0 0 0 16px #3dbcf6;
}
}
.rc-checkbox-disabled.rc-checkbox-checked:hover .rc-checkbox-inner {
border-color: #d9d9d9;
}
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
background-color: #f3f3f3;
border-color: #d9d9d9;
}
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner:after {
animation-name: none;
border-color: #cccccc;
}
@media print {
.rc-checkbox-disabled.rc-checkbox-checked .rc-checkbox-inner {
  box-shadow: inset 0 0 0 16px #f3f3f3;
}
}
.rc-checkbox-disabled:hover .rc-checkbox-inner {
border-color: #d9d9d9;
}
.rc-checkbox-disabled .rc-checkbox-inner {
border-color: #d9d9d9;
background-color: #f3f3f3;
}
.rc-checkbox-disabled .rc-checkbox-inner:after {
animation-name: none;
border-color: #f3f3f3;
}
.rc-checkbox-disabled .rc-checkbox-inner-input {
cursor: default;
}
@keyframes amCheckboxIn {
0% {
  opacity: 0;
  transform-origin: 50% 50%;
  transform: scale(0, 0) rotate(45deg);
}
100% {
  opacity: 1;
  transform-origin: 50% 50%;
  transform: scale(1, 1) rotate(45deg);
}
}
@keyframes amCheckboxOut {
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
}

.player-wrapper {
position: relative;
padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.createPasswordView {
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 4;
}

@media only screen and (max-width: 600px) {
  .player-wrapper {
    width: 300px !important;
    align-items: center !important;
    align-self: center !important;
    justify-content: center !important;
  }
  .paddingBottomfooterData {
    padding-bottom: 150px !important;
  }
  .createPasswordView {
    margin-top: 80px;
    margin-bottom: 50px;
  }
  .page-footer {
    position: relative !important; 
  }
}

.react-player {
position: absolute;
top: 0;
left: 0;
}

/* .cke_top {
background: 0% 0% no-repeat padding-box padding-box rgb(195 191 191) !important;
}
.cke_bottom {
background: 0% 0% no-repeat padding-box padding-box rgb(195 191 191) !important;
} */
.react-responsive-modal-modal {
border-radius: 8px !important;
padding: 0px !important;
/* min-height: 150px;
max-height: 500px; */
overflow-y: auto;
}

.react-calendar__navigation button {
color: white !important;
}

.editProfileGridStyle {
padding-left: 20px;
padding-right: 80px;
padding-top:  20px;
}

@media only screen and (max-width: 990px) {
.editProfileGridStyle {
  padding-right: 20px !important;
}  
}

.editProfilePlusHover:hover { 
background-color: #1891BD;
}

.editProfilePlusHover:hover span { 
color: #ffffff !important;
}

.fixed-top {
/* z-index: 2 !important; */
z-index: 10000 !important;
}


@media only screen and (max-width: 350px) {
li {
  padding-top: 10px !important;
  padding-right: 10px !important;
  padding-left: 10px !important; 
}  
}

@media only screen and (max-width: 520px) {
.homeLogo {
  padding: 0px !important;
  padding-top: 20px !important;
  padding-left: 0px !important;
  align-self: center !important;
  align-items: center !important;
}
.homeLogoRestrict {
  padding-top: 20px !important;
}
}

#multiselectContainerReact {
background: rgba(75, 76, 92, 0.6) !important;
color: rgb(255, 255, 255) !important;
font: italic 300 14px / 18px Poppins !important;
border: none !important;
width: 100% !important;
outline: none !important;
border-radius: 5px !important;  
padding: 5px !important;
}

.search-wrapper{
border: none !important;
}

.search-wrapper > .searchBox{
border: none !important;
color: rgb(255, 255, 255) !important;
font: italic 300 14px / 18px Poppins !important;
width: 100% !important;
}

.chip {
color: rgb(255, 255, 255) !important;
background: #1891BD !important;
font: italic 300 14px / 18px Poppins !important;
}

.optionContainer {
border: none !important;
/* background: #1891BD !important; */
}

.optionListContainer {
background-color: #51535d !important;
padding: 0;
margin-left: -5px !important;
margin-top: 5px !important;
border: none !important;
border-radius: 4px;
max-height: 250px;
overflow-y: auto;
}

.optionContainer > li {
width: 100% !important;
background: none !important;
}

.optionContainer > li:hover {
width: 100% !important;
background: #1891BD !important;
}

input:-internal-autofill-selected {
background: transparent !important;
}

.commonBackClass:hover {
color: #1891BD !important
}

.react-calendar__navigation button:hover {
/* background: rgba(75, 76, 92, 0.6) !important; */
color: #000 !important;
}

.react-calendar__navigation button:focus {
/* background: rgba(75, 76, 92, 0.6) !important; */
color: #000 !important;
}

.react-date-picker__inputGroup__day {
/* width: 20px !important; */
}

.react-date-picker__inputGroup__leadingZero {
font: italic normal 300 14px/21px Poppins !important;
letter-spacing: 3px !important;
color: #FFFFFF !important;
opacity: 0.64 !important;
}

.my-anchor-css-class {
color: #1891BD !important;
font: normal normal normal 14px/21px Poppins !important;
}
.content-css {
font: normal normal normal 14px/21px Poppins !important;
}

.swiper-container {
margin-left: auto;
margin-right: auto;
position: relative;
overflow: hidden;
list-style: none;
padding: 0;
z-index: 1
}

.swiper-container-vertical>.swiper-wrapper {
flex-direction: column
}

.swiper-wrapper {
position: relative;
width: 100%;
height: 100%;
z-index: 1;
display: flex;
transition-property: transform;
box-sizing: content-box
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
transform: translate3d(0px, 0, 0)
}

.swiper-container-multirow>.swiper-wrapper {
flex-wrap: wrap
}

.swiper-container-multirow-column>.swiper-wrapper {
flex-wrap: wrap;
flex-direction: column
}

.swiper-container-free-mode>.swiper-wrapper {
transition-timing-function: ease-out;
margin: 0 auto
}

.swiper-container-pointer-events {
touch-action: pan-y
}

.swiper-container-pointer-events.swiper-container-vertical {
touch-action: pan-x
}

.swiper-slide {
flex-shrink: 0;
width: 250px !important;
height: 100%;
position: relative;
transition-property: transform;
margin: 5px !important;
margin-left: 0px !important;
}

.swiper-slide-invisible-blank {
visibility: hidden
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
height: auto
}

.swiper-container-autoheight .swiper-wrapper {
align-items: flex-start;
transition-property: transform, height
}

.swiper-container-3d {
perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
pointer-events: none;
z-index: 10
}

.swiper-container-3d .swiper-slide-shadow-left {
background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-right {
background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-top {
background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-bottom {
background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-css-mode>.swiper-wrapper {
overflow: auto;
scrollbar-width: none;
-ms-overflow-style: none
}

.swiper-container-css-mode>.swiper-wrapper::-webkit-scrollbar {
display: none
}

.swiper-container-css-mode>.swiper-wrapper>.swiper-slide {
scroll-snap-align: start start
}

.swiper-container-horizontal.swiper-container-css-mode>.swiper-wrapper {
scroll-snap-type: x mandatory
}

.swiper-container-vertical.swiper-container-css-mode>.swiper-wrapper {
scroll-snap-type: y mandatory
}

.swiper-button-prev {
position: absolute;
top: 50%;
width: 30px;
height: 30px;
z-index: 1;
cursor: pointer;
background-size: 50px 50px;
background-position: center center;
background-repeat: no-repeat;
margin-top: -22px !important;
right: auto;
left: 5px;
}

.swiper-button-next {
position: absolute;
top: 50%;
width: 30px;
height: 30px;
z-index: 1;
cursor: pointer;
background-size: 50px 50px;
background-position: center center;
background-repeat: no-repeat;
margin-top: -22px !important;
right: 5px;
left: auto;
}

.swiper-pagination {
position: relative;
text-align: center;
transition: 300ms opacity;
transform: translate3d(0, 0, 0);
z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
bottom: 10px;
left: 0;
width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
overflow: hidden;
font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
transform: scale(0.33);
position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
transform: scale(0.33);
}
.swiper-pagination-bullet {
width: 8px;
height: 8px;
display: inline-block;
border-radius: 50%;
background: #000;
opacity: 0.2;
}
button.swiper-pagination-bullet {
border: none;
margin: 0;
padding: 0;
box-shadow: none;
-webkit-appearance: none;
   -moz-appearance: none;
        appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
cursor: pointer;
}
.swiper-pagination-bullet-active {
opacity: 1;
background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-container-vertical > .swiper-pagination-bullets {
right: 10px;
top: 50%;
transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
margin: 6px 0;
display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
top: 50%;
transform: translateY(-50%);
width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
display: inline-block;
transition: 200ms transform, 200ms top;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
left: 50%;
transform: translateX(-50%);
white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
transition: 200ms transform, 200ms left;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
transition: 200ms transform, 200ms right;
}
/* Progress */
.swiper-pagination-progressbar {
background: rgba(0, 0, 0, 0.25);
position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
background: var(--swiper-pagination-color, var(--swiper-theme-color));
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
transform: scale(0);
transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
width: 100%;
height: 4px;
left: 0;
top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar,
.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
width: 4px;
height: 100%;
left: 0;
top: 0;
}
.swiper-pagination-white {
--swiper-pagination-color: #ffffff;
}
.swiper-pagination-black {
--swiper-pagination-color: #000000;
}
.swiper-pagination-lock {
display: none;
}

.MuiButton-label{
white-space: nowrap !important;
}

label + .MuiInput-formControl {
margin-top: 10px !important;
}

.MuiFormControl-fullWidth {
font: italic normal 300 14px/18px Poppins;
color: #FFFFFF;
border: none;
opacity: 0.7;
height: 48px !important;
position: relative;
border-radius: 5px;
letter-spacing: 0;
background-color: #51535d;
padding-right: 10px;
padding-left: 10px;
}
#demo-simple-select-label {
color: #fff !important;
background-color: transparent !important;
border: none !important;
font: normal normal 300 14px/18px Poppins !important;
padding-right: 10px;
padding-left: 10px;
margin-top: -10px;

}
#demo-simple-select {
color: #fff !important;
background-color: transparent !important;
border: none !important;
font: normal normal 300 14px/18px Poppins;
padding-right: 10px;
padding-left: 10px;
/* padding-top: 10px; */
}
.MuiInput-underline:before {
border-bottom: none !important;
}
.MuiInput-underline::after {
border-bottom: none !important;
}
.MuiFormLabel-filled {
padding-left: 15px !important;
padding-right: 15px !important;
margin-top: 3px !important;
}











label.MuiInputLabel-formControl{
color:#fff  
}
.MuiInputBase-input.MuiOutlinedInput-input{
color: #fff;
}
.Mui-disabled.Mui-disabled.MuiFormLabel-filled{
color: darkgrey;
}
.MuiTextField-root{
height: 56px !important;
}
.MuiFormControl-root{
width: 100% !important;
}



.Password .MuiSvgIcon-root {
width: 20px;
}

.Mui-focused {
color: #fff !important;
border: none !important;
outline: none !important;
}
.Mui-focused:focus {
outline: none !important;
border: none !important;
}
.Mui-focused:hover {
outline: none !important;
border: none !important;
}
.Mui-focused::selection {
outline: none !important;
border: none !important;
}

.MuiOutlinedInput-notchedOutline {
border-color: transparent !important;
}
.MuiOutlinedInput-notchedOutline:hover{
border-color: transparent !important;
}


#overlay {
background: rgba(0,0,0,0.4);
color: #666666;
position: fixed;
height: 100%;
width: 100%;
z-index: 5000;
top: 0;
left: 0;
float: left;
text-align: center;
padding-top: 25%;
opacity: .80;
}
.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid #0d6686;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.chatBorder {
  position: absolute;
  border: 1px solid #3B3E50; 
  width: 0; 
}
@media only screen and (max-width: 768px) {
  .chatBorder {
    position: inherit;
    border: inherit; 
    width: 0; 
  }
}

.signUpView .MuiSelect-icon {
  top: 10px !important;
}

.MuiTablePagination-caption {
  padding-bottom: 16px !important;
}

.MuiTablePagination-actions {
  margin-bottom: 10px !important;
}


.norecordfound{
  display: flex; 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%
}

.norecordfound > div{
  font: 14px / 21px Poppins;
  color: rgb(255, 255, 255);
  font-size: 15px
}

.MuiPopover-paper {
  max-height: 300px !important;
  background: rgb(81 83 93) !important;
}

.selectsingleselect .search-wrapper {
  display: flex !important;
}

.selectsingleselectadmin .search-wrapper {
  display: flex !important;
}

.selectsingleselectadmin #multiselectContainerReact {
  background: rgb(81, 83, 93) !important;
}

.singleSelectAgentSerachColor #multiselectContainerReact {
  background: rgb(81, 83, 93) !important;
}

.flag-dropdown  {
  background: #454555 !important;
  border-color: #454555 !important;
}

.country-list  {
  background: #454555 !important;
  border-color: #454555 !important;
}

.country-list .active {
  background: #2e303e !important;
}

.country-list .highlight {
  background: #2e303e !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #2e303e !important;
}

.singleSelectAgentSerachColorSelect #search_input {
  display: contents !important;
}
.labelPhone {
  padding-left: 0px !important;
  padding: 0px !important;
  height: auto !important;
  width: 150px !important;
}

.labelPhoneAgent {
  padding-left: 0px !important;
  padding: 0px !important;
  height: auto !important;
  width: 150px !important;
}
.react-tel-input .country-list .country:hover {
  background-color: #2e303e !important;
}

.autocomplete-input{
  background: #474952 !important;
  border-color: #474952 !important;
  border-left-width: 0px !important;
  border-left-color: #474952 !important;
  border-top-width: 0px !important;
  border-top-color: #474952 !important;
  border-radius: 4px !important;
  color: #fff !important;
  height: 45px !important;
  font: normal normal 300 14px/18px Poppins !important;
  letter-spacing: 0.3px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.autocomplete-input::-webkit-input-placeholder {
  color: #bdbec1 !important;
  font-size: 12px !important;
}
.autocomplete-input:focus {
  background: #474952 !important;
  border-color: #474952 !important;
  outline: 0px solid crimson !important;
}
.autocomplete-input:focus-visible {
  background: #474952 !important;
  border-color: #474952 !important;
  outline: 0px solid crimson !important;
}
.datalist-active-item {
  background: #2d3139 !important;
  border-color: #464a55 !important;
  
}
.default-datalist-items > div {
  background: #2d3139 !important;
  border-color: #464a55 !important;
  color: #fff;
  
}
.datalist-input .default-datalist-items {
  border: 1px solid #474952 !important;
  max-height: 200px !important;
  overflow-y: scroll !important;
}
/* .customModal {
  max-width: 1000px !important;
  width: 100%;
}
*/